
$color_principal:rgb(4, 17, 29);
$color_letra:rgb(255, 255, 255);
$color_titulos:rgb(255, 255, 255);
$color_caja_titulo:rgb(255, 0, 0);
$color_caja_texto:hsl(195, 100%, 1%);
$tipo_fuente:arial, verdana, sans-serif;
$tamaño_fuente:20px;
$alto40:100px;
$ancho40:100px;
$tamañoparatexto:90px;
$color_tex:rgba(231, 13, 13, 0.505);
$color_nav:rgba(28, 8, 8, 0.505);

.titulo{
    color: #f2e600;
}
.separador{
    width: 100%;
    height: auto;
   // background-color: #0d98ef;
}

.menu{
    background-color: #fdc200;
}
.menu1{
    background-color: #f5eeee;
}
a{
//margin-right: 2rem;
//display: flex;
 // justify-content: center;
 text-align: center;
 display: inline-block;
 width: 20%;
 

}

.titulo{
    font-weight:600;
  
    span{
        font-weight: bold;
    } 
}
.logo{
    height: 140px;
    width: 140px;
}

.menuarriba{
    //text-align: center;
    //align-items: center;
    margin: 0 auto;
   
}
.futer{
 background-color: #000000;
    //$color_caja_texto:hsl(195, 100%, 1%);
    color: #f2e600;
    text-align: center;
    height: 140px;
}
.p{
   
   
    display: inline-block;
    width: 20%;
}
.textoelectricidad{
    text-align: center;
}
.tituloelectricidad{
    text-align: center;
}
.titulorobotica{
    text-align: center;
}
.textorobotica{
    text-align: center;
}
.robot{
    height: 500px;
    width: 500px;
    display: inline-block;
}

#carrusel{
// //     height: 600px;
// //     width: 1500px;
// //    // display: block;
margin: 1% auto;
   
}
.btcolor{
    background-color: rgb(15, 61, 61);
}
.quienes{
    text-align: center;
}
.textoinicio{
    text-align: center;
    text-align: justify;
    height: 300px;
    width: 900px;
    margin: 20vh auto;
    
    
}

// .video2{
//     height: 50%;
//     width: 100%;
//     margin: -3vh auto;
//     // text-align: center;
//     // text-align: justify;
//   //align-content:space-around;
//    // align-items: center;
 

   
// }

.v1{
    height: 50%;
    width: 100%;
    margin: -3vh auto;
}

.icono1{
    height: 50px;
    width: 50px;
}
